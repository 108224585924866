<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="product.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-card>
            <v-card-text>
              {{ $t('product.fields.name') }}: {{values.name }} <br/>
              {{ $t('product.fields.articleNumber') }}: {{values.articleNumber }} <br/>
            </v-card-text>
          </v-card>

          <v-subheader class="pa-0">{{ $tc('product.title', 1) }}</v-subheader>
          <v-card>
            <v-card-text>
              <ProductTypesAutocomplete field="productType" v-model="values.productType"/>
              <KTextField type="number" field="customsPrice" v-model="values.customsPrice" prefix="€"/>
              <KCheckbox field="isActive" v-model="values.isActive"/>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import Product from '@/application/models/product.js';
import ProductTypesAutocomplete from '@/components/autocompletes/ProductTypes.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';

export default {
  name: 'ProductForm',
  extends: Form,
  components: {
    KCheckbox,
    KTextField,
    ProductTypesAutocomplete,
    KFieldGroup,
  },
  data: () => ({
    values: new Product(),
  }),
};
</script>
